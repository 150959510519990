<template>
  <div class="w-full max-h-screen bg-background flex flex-col">
    <page-header title="FAQ" backTo="faqs" :enableLogicalBack="true">
      <AppButton color="indigo" label="Print FAQ" @click="printFAQReport" :icon=true class="mr-2">
        <template v-slot:icon>
          <Icon iconType="print" class="mr-2"/>
        </template>
      </AppButton>
    </page-header>

    <main class="p-6 bg-background flex-1 overflow-y-scroll" id="main-content">
      <div v-if="faq">



        <div class="w-full bg-white p-5 rounded shadow-md">
  <span class="float-right text-gray-500">
    Created on {{ this.$moment.unix(faq.created_at).format("DD/MM/YYYY") }}
  </span>

          <div class="mb-4">
            <div class="grid grid-cols-1 md:grid-cols-4 gap-2">
              <!-- First Row -->
              <div class="flex items-center justify-center col-span-1 text-orange-700 bg-orange-200 p-2 rounded-lg md:w-60">
                <h2 v-if="faq.faq_number" class="text-3xl">
                  <span class="font-bold">FAQ ID:</span><span>#{{ faq.faq_number }}</span>
                </h2>
              </div>
              <div class="p-2 md:col-span-3">
                <h2 v-if="faq.title" class="text-3xl">
                  <span>{{ faq.title }}</span>
                </h2>
              </div>

              <!-- Second Row -->
              <div class="flex items-center justify-center col-span-1 text-orange-700 bg-orange-200 p-2 rounded-lg md:w-60">
                <h2>
                  <span class="font-bold">Associated Products</span>
                </h2>
              </div>
              <div class="p-2 md:col-span-3">
                <h3>
                  <span>{{ faq.products.map(e=>e.product_code).join(", ") }}</span>
                </h3>
              </div>

              <!-- Third Row -->
              <div class="flex items-center justify-center col-span-1 text-orange-700 bg-orange-200 p-2 rounded-lg md:w-60">
                <h2>
                  <span class="font-bold">Product Category</span>
                </h2>
              </div>
              <div class="p-2 md:col-span-3">
                <h3>
                  <span>{{ faq.category }}</span>
                </h3>
              </div>
            </div>
          </div>
        </div>









        <div class="w-full bg-white p-5 rounded shadow-md mt-6">
          <div class="flex items-center mb-5">
            <h3
              class="uppercase text-lg font-semibold text-primary tracking-wide"
            >
              Description
            </h3>
            <hr class="flex-1 border-primary mx-4" />
          </div>
          <p>
            {{ faq.description || "(No description added)" }}
          </p>
        </div>
        <div class="w-full bg-white p-5 rounded shadow-md mt-6">
          <div class="flex items-center mb-5">
            <h3
              class="uppercase text-lg font-semibold text-primary tracking-wide"
            >
              Resolution
            </h3>
            <hr class="flex-1 border-primary mx-4" />
          </div>
          <p>
            {{ faq.resolution || "(No resolution added)" }}
          </p>
        </div>

        <div class="w-full bg-white p-5 rounded shadow-md mt-6">
          <FaqComments :faqId="this.faqId" />
        </div>

        <div class="w-full bg-white p-5 rounded shadow-md mt-6">
          <FaqDocuments :faq="faq" />
        </div>
      </div>

      <div v-if="error">
        <div
          class="bg-red-200 border border-red-500 rounded-lg py-4 px-5 text-red-900"
        >
          <p>
            Couldn't load faq with ID {{ this.faqId }}:
            {{ error.statusText }}
          </p>
        </div>
      </div>

      <div v-if="viewState == 'Error'">
        <div
          class="bg-red-200 border border-red-500 rounded-lg py-4 px-5 text-red-900"
        >
          <p>
            Something went wrong loading the faq, please try again. If the
            problem persists, please contact your support team.
          </p>
        </div>
      </div>
    </main>
    <portal to="overlay-outlet">
      <Panel
        :showing="showingUploadDocumentPanel"
        @close="closePanel"
        title="Add Document"
      >
        <UploadPublishedDoc
          :document="editingDoc"
          :showAddExisting="true"
          :resource="documentResource"
          @complete="onComplete"
        />
      </Panel>
    </portal>
  </div>
</template>

<script>
const PageHeader = () => import("@/components/PageHeader.vue");
const Panel = () => import("@/components/Panel.vue");
const Notification = () => import("@/components/Notification.vue");
const FaqComments = () => import("@/components/Faqs/Comments/Comments.vue");
const UploadPublishedDoc = () =>
  import("@/components/Faqs/UploadPublishedDoc.vue");
const FaqDocuments = () => import("@/components/Faqs/Documents.vue");

import AppButton from '@/components/Button.vue';
import Icon from '@/components/Icon.vue';

import printJS from "print-js";
export default {
  name: "faq-edit",
  props: {
    faqId: String,
  },
  components: {
    PageHeader,
    Panel,
    Notification,
    FaqComments,
    UploadPublishedDoc,
    FaqDocuments,
    AppButton,
    Icon
  },
  data() {
    return {
      faq: null,
      comments: [],
      viewState: "Idle",
      error: null,
      showingUploadDocumentPanel: false,
      editingDoc: {
        visibility: "Internal",
      },
      documentResource: null,
      documents: "",
    };
  },
  async mounted() {
    await this.loadfaq();
    this.documentResource = {
      resource_type: "faq",
      resource_id: this.faqId,
    };
  },
  methods: {
    printMain() {
      printJS({
        printable: "main-content", // ID of the main tag
        type: "html",
      });
    },

    loadfaq: async function () {
      this.viewState = "Loading";

      try {
        let result = await this.faqService.getFaqById(this.faqId);

        this.faq = result;
      } catch (error) {
        console.error(error);
      } finally {
        this.isBusy = false;
      }
    },
    closePanel: function () {
      this.editingDoc = {
        visibility: "Internal",
      };
      this.showingUploadDocumentPanel = false;
    },
    onComplete: async function () {
      this.closePanel();
    },

    dynamicValues() {
      const data = this.faq; // Assuming faqData contains your FAQ data
      return {
        faqId: data.faq_number || "N/A",
        products: data.products.map(e=>e.product_code).join(", ") || "N/A",
        documents: this.documents || "N/A",
        category: data.category || "N/A",
        createdAt: this.formatDate(data.created_at),
        title: data.title || "N/A",
        description: data.description || "N/A",
        resolution: data.resolution || "N/A",
        comments: this.formatComments(data.comments),
      };
    },

    formatDate(timestamp) {
      return new Date(timestamp * 1000).toLocaleDateString("en-US"); // Modify date format as needed
    },

    formatComments(comments) {
      if (!comments || !comments.length) {
        return "No comments available";
      }
      return comments
        .map((comment) => {
          const commentDate = this.formatDate(comment.created_at);
          return `<div class="comment">
                <p>${comment.comment}</p>
                <p class="date-created">${comment.created_by} on ${commentDate}</p>
              </div>`;
        })
        .join("");
    },

    async loadHtmlTemplate() {
      try {
        const response = await fetch("/report_faq.html"); // Your HTML template path
        if (!response.ok) {
          throw new Error("Failed to load FAQ report template");
        }
        this.htmlTemplate = await response.text();
      } catch (error) {
        this.error = error.message;
      }
    },

    replacePlaceholders() {
      let htmlContent = this.htmlTemplate;
      const dynamicData = this.dynamicValues();
      Object.keys(dynamicData).forEach((key) => {
        const value = dynamicData[key];
        const regex = new RegExp(`{{${key}}}`, "g");
        htmlContent = htmlContent.replace(regex, value);
      });
      return htmlContent;
    },

    async printFAQReport() {
      await this.loadHtmlTemplate();
      if (this.error) {
        console.error(this.error);
        return;
      }
      await this.loadDocuments();
      const printContent = this.replacePlaceholders();

      printJS({
        printable: printContent,
        type: "raw-html",
      });
    },
    async loadDocuments() {
      if (this.faq.faq_id) {
        try {
          let result = await this.faqService.getDocuments(this.faq.faq_id);
          this.documents = result.data
            .map((doc) => doc.document_number)
            .join(", ");
        } catch (error) {
          console.error("Failed to fetch documents:", error);
          this.documents = "Failed to load documents";
        }
      }
    },
  },
};
</script>
